import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/logo-dradel.gif'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon">
        <img src={pic01} alt="" width="84" />
      </span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Crypto Chanukah</h1>
        <h3>
          Gift for all holidays and Celebrations.
        </h3>
        {/*
        <p>
        Webinar Today! 
        <br/>
        Wednesday Feburary 24th 2021
        <br/>
        5:30 PM - 7:00 PM (MST)
        <br/>
        <a href="/webinar/">Details and login link</a>
        </p>
        */}

{/*     Version 2 -- Need a bigger place for the security give away.
        <p>
          Gift Certificates for all holidays and occasions
          <br />
          Check out our free{' '}
          <a href="https://html5up.net/license">security check up</a>.
        </p>
*/}

{/*     Version 1
        <p>
          Gift cryptocurriencies with a sentimental certificate of deposit.
          <br />
          Eight days of giving, <b>give a crypto wallet!</b>
        </p>
*/}
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('giftcerts')
            }}
          >
            Gift Certificate
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('moregoodies')
            }}
          >
            More Goodies
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
