import PropTypes from 'prop-types'
import React from 'react'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import pngcertificate from '../images/Certificate COMP - Clous Hansen.png'
import pngexodus from '../images/ExodusExample.png'
import jpgfolder from '../images/original_blackbrown_folder1.jpg'
import jpgusbond from '../images/25-dollar-us treasury4.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Introduction</h2>
          <p>
            Back when I was a kid, during a holliday,
            my family purchased United States Savings Bonds as gifts.
            It was their way of teaching about money and personal decisions.
            I watched the bond grow, slowly, over the years. I learned about the banking system
            and financial responsibility. It was my decision when to withdraw the money.
          </p>
          <span className="image main">
            <img src={jpgusbond} alt="" />
          </span>
          <p>
            Today we have a new class of assets, Crypto Currency. After Bitcoin showed success,
            many similar technologies have emerged. Now there are 1,000s of unique crypto currencies,
            and many more uses. Our society has determined it's
            worth and are willing to exchange fiat money for crypto currency.
            Today, people are buying Crypto Currency to exchange for goods and services,
            and as a part of their investment strategy.
          </p>
          <p>
            It is our mission to provide a beautiful gift basket of Crypto Currency and Education.
            Each gift contains a certificate to acknowledge your gift and a packet of information about
            the technology.
            {/* In this decentralized space, there is no loner a need for a bank or other financial institution.
            With freedom comes responsibility. */}
          </p>
          <p>
          {' '}
          </p>
          {close}
        </article>

        <article
          id="wallet"
          className={`${this.props.article === 'wallet' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Getting Started</h2>
          <span className="image main">
            <img src={pngexodus} alt="" />
          </span>
          <p>
            The first thing to do when giving the gift of Crypto is build a wallet.
            In order to give Crypto Currency, you must put it into something that can be
            transfered just like a wallet.
          </p>
          <p>
            If you gifted your family member cash money, you would put it into an envelope
            or wallet like container. This container would hold the cash money for safe keeping
            until the reciepent wanted to use it. In the crypto world, this is called a digital wallet,
            or wallet for short. (nothing new here)
          </p>
          <p>
            Anyone can create a wallet. This can be done for free through a software program.
            Additionally you can purchase a hardware wallet, that creates an authentication process
            which requires the physical device to allow the transactions. This is a smart idea for
            the highest security. Either option is a possibility for your gift.
          </p>
          <p>
            The following will describe how to create a free wallet. We will do this with a free
            software program. There are many free programs out there that will enable you to do the
            same. This example will get you started with a well known and easy to use program
            called Exodus. (or Atomic Wallet).
          </p>
          <p>
            ...
          </p>
          {close}
        </article>

        <article
          id="giftcerts"
          className={`${this.props.article === 'giftcerts' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Gift Certificate</h2>
          <p>
            Each certificate is uniquely designed to match with the logo and design of the
            Crypto Currency choosen.
            Your reciepent's name will be listed front and center.
          </p>
          <span className="image main">
            <img src={pngcertificate} alt="" />
          </span>
          <p>
            The information contained in the certificate is meant as a learning tool.
          </p>
          <p>
            The Crypto Currency name and logo will appear at the top of the certificate.
          </p>
          <p>
            A description will provide a variety of information about the Crypto Currency. Information might contain
            when it was first brought into circulation. Perhaps there's a unique story about the person who created it.
            The Crypto Currency might have unique utility that set it apart from others. Each have a special story to tell.
          </p>
          <p>
            {/*Additionally, data points unique to the date given will prvide a unique look back. */}
            The Circulation Supply is the number of "units" that are available in the world today.
            This gives a sense of how 'scarce' that particular Crypto Currency is. This number could change in the
            future because Miners can 'mint' new crypto as part of the design. This minting process is based on
            a specific algorithm, not to be taken lightly.
          </p>
          <p>
            The Average Price will show a point in time what the crypto was worth. Currency fluxuates over
            time, and crypto is no different.
          </p>
          <p>
            The certificate is the center piece of the gift. It's meant to be displayed, not tucked away.
            However, it's just one of the learning tools contained in the gift basket!
          </p>
          <p>
            &nbsp;
            Shop <a href="/crypto">Gift Certificates</a>
          </p>
          {close}
        </article>

        <article
          id="moregoodies"
          className={`${this.props.article === 'moregoodies' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Gift Basket</h2>
          <p>
            Many additional goodies will assist in learning about Crypto. These goodies are included with
            each Certificate purchased.
          </p>
          <span className="image main">
            <img src={jpgfolder} alt="Gift Basket of Learning" />
          </span>
          <h3>Welcome Letter</h3>
          <p>
            Included is a welcome letter describing the Certificate and Crypto Currency.
          </p>
          <h3>Dictionary of Terms</h3>
          <p>
            A dictionary of Crypto Currency and financial terms.
          </p>
          <h3>Security Practices</h3>
          <p>
            A 'Best Security Practices' document.
          </p>
          <h3>Seed Phrase Template</h3>
          <p>
            If you choose to create your own wallet, this template will be used to gift the wallet.
          </p>
          <hr/>
          <p>
            These educational pieces are our pride and joy. Money should be understood more now than ever.
            If our society is to move away from physical currency, we must put more emphasis on the power
            and responsibility of money.
            {/*
            With decentralized money, we are all empowered to manage money ourself.
            With this power comes great responsibility and self sovereignty.
          */}
          </p>
          <p>
            &nbsp;
            <a className="special btn" href="/crypto">Select Crypto</a>
            <br/>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="/contact/index.php">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <div className="field">
              <label htmlFor="bitcoin">Verify you are human, Enter Price of Bitcoin Today</label>
              <input type="text" name="bitcoin" id="bitcoin" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {/*
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
