import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>

        <p className="referral">
        	<a href="/referral">Referral Links</a> | 
        	Security Checkup | 
        	Contact Us
        </p>

        <p className="copyright">&copy; <a href="http://cryptochanukah.com">Crypto Chanukah</a>, 2021  Powered by{' '}
          <a href="https://biolumina.life">Biolumina</a>.</p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
